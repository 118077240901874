@import "../../../../styleFunctions/mixins";

.becomeFleetMemberForm {
  > div {
    @include flexContainer($gap: 2rem);
    width: 100%;
    > div {
      @include flexContainer($gap: 0.5rem);
      flex-direction: column;
      width: 100%;
      position: relative;
      .errorText {
        position: absolute;
        bottom: -3.5rem;
        left: 0;
      }
    }
    .toolTipLabel {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;
      position: relative;
      label {
        width: auto;
      }
    }
  }
  .formTitle {
    width: 100%;
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin: 0;
  }
}

@media only screen and (max-width: 600px) {
  .becomeFleetMemberForm {
    > div {
      flex-direction: column;
    }
    .formBtnContainer {
      flex-direction: row;
    }
  }
}
