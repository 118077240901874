@import "../../styleFunctions/mixins";

.cancoCashPage {
  .bannerSection {
    @include backgroundImage($image: url(../../assets/cancoCashBanner.jpg));
    min-height: 45rem;
  }
  .myAccountSection {
    background-color: var(--tertiaryColor);
    padding-block: 2rem;
    position: sticky;
    top: 0;
    z-index: 9999;
    > div {
      padding-top: 0;
      > div {
        align-items: center;
        .userBlock {
          @include flexContainer($justifyContent: flex-start);
          h2 {
            margin: 0;
          }
          .userEditIcon {
            background-color: var(--primaryColor);
            border-radius: 50%;
            color: var(--whiteColor);
            padding: 1rem;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
              background-color: transparent;
              color: var(--primaryColor);
            }
          }
        }
        .myaccountBtn {
          @include flexContainer($alignItems: flex-end, $gap: 1rem);
          flex-direction: column;
          .afterLoginBtn {
            width: 100%;
            @include flexContainer($justifyContent: flex-end, $gap: 4rem);
            > button {
              &:nth-of-type(1),
              &:nth-of-type(2) {
                background: none !important;
                border: none !important;
                color: var(--whiteColor) !important;
                padding: 0 !important;
                position: relative;
                &:after {
                  content: "";
                  background-color: var(--whiteColor);
                  width: 100%;
                  height: 0.2rem;
                  position: absolute;
                  bottom: 0px;
                }
                &:hover {
                  color: var(--primaryColor) !important;
                  &:after {
                    background-color: var(--primaryColor);
                  }
                }
              }
            }
          }
          button {
            padding: 1rem 3rem;
          }
          p {
            color: var(--whiteColor);
            margin: 0;
            a {
              color: var(--primaryColor) !important;
            }
          }
        }
      }
    }
  }
  .aboutCancocard {
    padding-bottom: 10rem;
    overflow: hidden;
    .aboutDescription {
      h2 {
        margin-bottom: 2rem;
      }
      p {
        margin: 0;
      }
      ul {
        @include flexContainer($alignItems: flex-start);
        flex-direction: column;
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
        li {
          @include flexContainer($justifyContent: flex-start, $gap: 1rem);
          p {
            font-weight: 600;
            font-size: 2.2rem;
          }
          .noteTxt {
            font-weight: 400;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .howWorkContainer {
    padding-bottom: 10rem;
    padding-top: 0;
    ul {
      @include flexContainer($alignItems: flex-start, $gap: 0);
      flex-direction: column;
      margin-top: 3rem !important;
      li {
        @include flexContainer($justifyContent: flex-start, $gap: 0);
        h2 {
          color: var(--primaryColor);
          font-size: 2.4rem;
          // padding: 0.5rem 2rem 0.5rem 0rem;
          flex: 1 0 10rem;
          border-right: 0.1rem solid var(--primaryGreyColor);
        }
        p {
          margin: 0;
          padding-left: 2rem;
          flex: 1 0 auto;
        }
      }
    }
  }
  .giftCardContainer {
    background-color: var(--tertiaryColor);
    padding-block: 3rem;
    > div {
      padding-top: 0;
    }
    .giftDescription {
      h2 {
        &:nth-of-type(2) {
          margin-top: 3rem;
        }
      }
    }
    .giftimg {
      position: relative;
      img {
        position: absolute;
        width: 100%;
        max-width: 50rem;
        top: -85px;
        filter: drop-shadow(2px 4px 6px rgb(0, 0, 0, 0.3));
        right: 0;
      }
    }
  }
  .sixthSection {
    padding-block-end: 10rem;
    .sixthSectionCol {
      @include flexContainer($justifyContent: flex-start, $gap: 0);
      overflow: hidden;
      > div {
        &:nth-of-type(1) {
          z-index: 1;
          img {
            max-width: 50rem;
          }
        }
        &:nth-of-type(2) {
          background-color: var(--primaryColor);
          padding: 2rem 7rem 2rem 12rem;
          border-radius: 2.1rem;
          margin-inline-start: -6rem;
          z-index: 0;
          h2 {
            margin-bottom: 2rem;
          }
        }
        .downloadApp {
          @include flexContainer($justifyContent: flex-start);
          margin-block-start: 3rem;
          a {
            display: block;
            width: 100%;
            max-width: 20rem;
            img {
              min-height: 6.2rem;
              object-fit: cover;
              border-radius: 1rem;
            }
          }
        }
      }
    }
  }
  > table {
    tr {
      th,
      td {
        border: 1px solid #000;
      }
    }
  }
  .cardContainer {
    margin-top: 6rem;
    > div {
      align-items: center;
    }
    .cardInfo {
      @include flexContainer;
      margin-top: 1rem;
      > p {
        width: 100%;
        &:nth-of-type(2) {
          text-align: right;
        }
      }
    }
    .cardDetailsContainer {
      position: relative;
      .loaderBox {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgb(255, 255, 255, 0.8);
        @include flexContainer($gap: 0);
        z-index: 3;
        top: 0;
        left: 0;
        border-radius: 1.1rem;
      }
      ul {
        @include flexContainer;
        flex-wrap: wrap;
        width: 100%;
        max-width: 80%;
        margin: 0 auto !important;
        li {
          flex: 1 0 45%;
          p {
            font-size: 2.4rem;
            margin: 0;
            span {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
  .missingPoint {
    display: block;
    width: 100%;
    text-align: right;
    color: var(--primaryColor) !important;
    margin-top: 7rem;
    font-size: 1.6rem;
    font-weight: 500;
    &:hover {
      text-decoration: underline !important;
    }
  }
  .transactionTable {
    margin-top: 1rem;
    overflow: hidden;
    border: 0.1rem solid var(--primaryGreyColor);
    border-radius: 1rem;
    width: 100%;
    table {
      width: 100%;
      table-layout: fixed;
      thead {
        tr {
          background-color: var(--primaryColor);
          th {
            padding: 2rem;
            color: var(--whiteColor);
            font-weight: 600;
            font-size: 1.8rem;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 1rem 2rem;
            font-size: 1.6rem;
          }
          border-bottom: 0.1rem solid var(--primaryGreyColor);
          &:last-child {
            border-bottom: 0px;
          }
        }
      }
    }
  }
  .seeMore {
    @include flexContainer($gap: 0);
    flex-direction: column;
    margin-top: 2rem;
    text-align: center;
    color: var(--primaryColor);
    span {
      cursor: pointer;
    }
  }
  .cardLoader {
    @include flexContainer($gap: 0);
    min-height: 15rem;
  }
}

@media only screen and (max-width: 990px) {
  .cancoCashPage {
    .myAccountSection {
      > div {
        > div {
          .userBlock {
            h2 {
              font-size: 2.6rem;
            }
          }
          .myaccountBtn {
            .afterLoginBtn {
              gap: 2rem;
            }
          }
        }
      }
    }
    .howWorkContainer {
      ul {
        li {
          p {
            flex: unset;
          }
          margin-bottom: 2rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .giftCardContainer {
      .giftimg {
        text-align: center;
        margin-top: 2rem;
        img {
          position: unset;
        }
      }
    }
    .sixthSection {
      .sixthSectionCol {
        > div {
          &:nth-of-type(1) {
            img {
              max-width: 80rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .cancoCashPage {
    .myAccountSection {
      > div {
        > div {
          .userBlock {
            justify-content: center;
            margin-bottom: 2rem;
          }
          .myaccountBtn {
            align-items: center;
            .afterLoginBtn {
              flex-wrap: wrap;
              > button {
                flex: 1 0 45%;
              }
            }
          }
        }
      }
    }
    .cardContainer {
      .cardDetailsContainer {
        ul {
          max-width: 100%;
          padding: 0 2rem !important;
          flex-direction: column;
          align-items: flex-start;
          li {
            p {
              font-size: 1.8rem;
            }
          }
        }
      }
    }
    .transactionTable {
      overflow: auto;
      table {
        table-layout: auto;
      }
    }
    .aboutCancocard {
      .aboutDescription {
        ul {
          li {
            svg {
              flex: 1 0 4rem;
            }
            > p {
              flex: 1 0 20rem;
            }
          }
        }
      }
    }
    .sixthSection {
      .sixthSectionCol {
        flex-direction: column;
        > div {
          &:nth-of-type(2) {
            margin-inline-start: 0rem;
            padding: 4rem;
            margin-block-start: 3rem;
          }
        }
      }
    }
  }
}
